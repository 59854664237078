import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import '../app/styles/globals.sass'
import { fbPixelTrackingId } from '../libs/next/fbPixel/index'
import { HydratingRoot } from '../libs/next/hydratation'

const queryClient = new QueryClient()

function MyApp({ Component, pageProps }: AppProps) {
	const router = useRouter()

	const isFirstPageView = useRef(true)

	useEffect(() => {
		const fbPixelId = fbPixelTrackingId()
		if (fbPixelId) {
			import('react-facebook-pixel')
				.then((x) => x.default)
				.then((ReactPixel) => {
					ReactPixel.init(fbPixelId)

					const pageView = () => {
						ReactPixel.pageView()
					}
					if (isFirstPageView.current === true) {
						isFirstPageView.current = false
						pageView()
					}

					router.events.on('routeChangeComplete', pageView)
					return () => {
						router.events.off('routeChangeComplete', pageView)
					}
				})
		}
	}, [router.events])

	return (
		<HydratingRoot>
			<QueryClientProvider client={queryClient}>
				<Component {...pageProps} />
			</QueryClientProvider>
		</HydratingRoot>
	)
}
export default MyApp
