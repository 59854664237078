import React from 'react'

let hydratedIndex = 1
const hydratedValues: Record<string, boolean | undefined> = {}

const HydratingContext = React.createContext(false)

export function useHydrated() {
	return React.useContext(HydratingContext)
}

export const HydratingRoot = React.memo<{ children: React.ReactNode }>(function HydratingRoot(
	props
) {
	const [index] = React.useState(() => hydratedIndex++)
	const [hydrated, setHydrated] = React.useState(() => hydratedValues[index] ?? false)

	React.useEffect(() => {
		hydratedValues[index] = true
		setHydrated(true)
	}, [index])

	return <HydratingContext.Provider value={hydrated}>{props.children}</HydratingContext.Provider>
})

export const IfHydrated = React.memo<{
	yes: () => React.ReactNode
	no?: () => React.ReactNode
}>(function IfHydrated(props) {
	const hydrated = useHydrated()

	return hydrated ? <>{props.yes()}</> : <>{props.no?.()}</>
})
