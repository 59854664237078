import { getConfig } from '../../../app/next/config'

export function fbPixelTrackingId(): string | null {
	const config = getConfig()?.publicRuntimeConfig ?? {}

	if ('FB_PIXEL_TRACKING_ID' in config) {
		return config?.FB_PIXEL_TRACKING_ID ?? null
	}

	return null
}
